import React from 'react';


function VersionFooter() {

    const [version, setVersion] = React.useState("PLACEHOLDER")

    React.useEffect(() => {
        fetch("/about").then(response => {
            return response.json();
            }
        ).then(res => {
            let remoteVersion = res.Program;
            if (remoteVersion && remoteVersion !== "") {
                setVersion(remoteVersion)
            }
        })
    })

    return (
        <footer id="sticky-footer" className="footer mt-auto py-3 text-center">
            <div className="container">
                <span className="text-muted">Versión {version}.</span>
            </div>
        </footer>
    );
}

export default VersionFooter;